import { useState } from 'react';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import HeroModule from '../../Organisms/Hero/HeroModule';
import NewsArchivePageModel from '../../NewsArchivePage/Models/NewsArchivePageModel.interface';
import ContentArea from '../../Organisms/ContentArea/ContentArea';
import NewsCard from '../../Organisms/NewsCard/NewsCard';
import LoadMoreContainer from '../../Molecules/LoadMoreContainer/LoadMoreContainer';
import NewsCardModel from '../../NewsCard/Model/NewsCardModel.interface';
import useMedia from '../../Shared/Hooks/useMedia';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { styled } from '../../stitches.config';
import { H2 } from '../../Atoms/Typography/Headings/Heading';
import { mediaQueryTypes } from '../../Theme/Settings/mediaQueries';

function NewsArchivePage() {
  const {
    heading,
    introText,
    bodyText,
    contentArea,
    teaserHeader,
    teaserHeaderSize,
    teaserButtonType,
    teaserImage,
    teaserLinks,
    teaserText,
    newsCards,
  } = useCurrentPage<NewsArchivePageModel>();

  const isDesktop = useMedia(mediaQueryTypes.mediaMinLarge);

  const {
    'newsPage/news': news,
    'newsPage/newsMobileCounter': newsMobileCounter,
    'searchPage/counter': counter,
    'searchPage/loadMore': loadMore,
  } = useTranslationData();

  const [newsCardsToShow, setNewsCardsToShow] = useState(
    newsCards.slice(0, 10)
  );
  const hasTopContentText = !!heading || !!introText || !!bodyText;
  const totalNewsCards = newsCards.length;
  const counterText = !!newsCards.length
    ? counter
        .replace('{0}', newsCardsToShow.length.toString())
        .replace('{1}', newsCards.length.toString())
    : '';
  const loaded = (newsCardsToShow.length / totalNewsCards) * 100;

  const mobileCounterText =
    !isDesktop && !!newsCards.length
      ? newsMobileCounter
          .replace('{0}', newsCardsToShow.length.toString())
          .replace('{1}', newsCards.length.toString()) +
        ' ' +
        news.toLowerCase()
      : '';

  const increaseNumberOfCardsShown = () => {
    setNewsCardsToShow(newsCards.slice(0, newsCardsToShow.length + 10));
  };

  return (
    <main>
      {teaserImage && (
        <HeroModule
          header={teaserHeader}
          summary={teaserText}
          image={teaserImage}
          links={teaserLinks}
          headerSize={teaserHeaderSize}
          buttonType={teaserButtonType}
        />
      )}
      {hasTopContentText && (
        <TopContent>
          <StyledH2>{heading}</StyledH2>
          <Intro hasBodyText={!!bodyText}>{introText}</Intro>
          <div
            dangerouslySetInnerHTML={{
              __html: bodyText,
            }}
          />
        </TopContent>
      )}
      <NewsCardsContainerContainer>
        {!!mobileCounterText.length && (
          <MobileCounterWrapper>{mobileCounterText}</MobileCounterWrapper>
        )}
        <NewsCardWrapper>
          {newsCardsToShow.map((card: NewsCardModel, index) => (
            <NewsCard
              key={card.href}
              item={card}
              isLastItem={index === newsCardsToShow.length - 1}
            />
          ))}
        </NewsCardWrapper>
        <LoadMoreContainer
          loaded={loaded}
          counterText={counterText}
          loadMore={loadMore}
          disabled={newsCardsToShow.length >= newsCards.length}
          onClick={increaseNumberOfCardsShown}
        />
        <ContentArea childItems={contentArea} />
      </NewsCardsContainerContainer>
    </main>
  );
}

const NewsCardsContainerContainer = styled('div', {
  w: '100%',
  maxW: '$contentMaxWidth',
  mx: 'auto',
  px: 4,
});

const MobileCounterWrapper = styled('div', {
  h: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fs: 5,
  ls: '$ls06',
  '@mediaMinSmall': {
    fs: 6,
  },
});

const NewsCardWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$white',
  wh: '100%',
  maxW: '$contentResultWidth',
  mb: 16,
  mx: 'auto',
  p: 8,
  '@mediaMinLarge': {
    mt: 16,
  },
});

const TopContent = styled('div', {
  mt: 6,
  w: '100%',
  maxW: '$contentResultWidth',
  mx: 'auto',
  '@mediaMaxLarge': {
    px: 4,
    mb: 6,
  },
  '@mediaMinLarge': {
    mt: 13,
  },
});

const StyledH2 = styled(H2, {
  maxW: '$maxWidthSmall',
  fs: 18,
  fontWeight: '$fw300',
  mb: 6,
  '@mediaMinLarge': {
    fs: 24,
    mb: 8,
  },
});

const Intro = styled('p', {
  variants: {
    hasBodyText: {
      true: {
        mb: 8,
      },
      false: {
        mb: 0,
      },
    },
  },
  fs: 8,
  fontWeight: '$fw300',
  lineHeight: '$lh15',
  ls: '$ls113',
  '@mediaMinLarge': {
    fs: 9,
  },
});

export default NewsArchivePage;
